<template>
  <div class="my-account-reset-password">
    <HeroImage :bg-image="'images/2-service-pages-hero.jpg'" :has-purple-bg="false">
      <div class="hero-title">
        <h3 class="title is-3 has-text-centered pt-6 txt-white">
        </h3>
      </div>
    </HeroImage>
    <section class="over-hero">
      <div class="container is-fullhd">
        <div class="my-account-reset-password-wrap">
          <div class="columns content-my-account-reset-password-wrap">
            <div class="column is-12 reset-password-wrap">
              <h4 class="title has-text-centered pb-5">Cambiar contraseña</h4>
              <form class="overflow-h" onsubmit="return false">
                <div class="field mb-5">
                  <input-text id="password" :placeholder="'Nueva contraseña* (min 8 char)'" v-model="password" :name="'password'" :is-required="false" :type="'password'"></input-text>
                </div>
                 <div class="field mb-5">
                  <input-text id="password-confirm" :placeholder="'Repetir la nueva contraseña*'" v-model="passwordConfirm" :name="'passwordConfirm'" :is-required="false" :type="'password'"></input-text>
                </div>
                <div class="field">
                  <p class="control">
                    <button class="button is-primary is-fullwidth is-medium mt-4" @click="resetPassword()" :disabled="passwordConfirm === '' || password === ''">
                      GUARDAR
                    </button>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import HeroImage from '@/components/HeroImage.vue'
import Footer from '@/components/Footer.vue'
import InputText from '@/components/FormElements/InputText.vue'
import {
  IsEmptyOrNull,
  AddClassById,
  RemoveClassById
} from '@/utils/utils.js'

export default {
  name: 'ClinicResetPassword',
  components: {
    HeroImage,
    Footer,
    InputText
  },
  data () {
    return {
      passwordConfirm: '',
      password: ''
    }
  },
  methods: {
    resetPassword () {
      const self = this
      const missingFields = this.formValidation()
      if (missingFields.length === 0) {
        this.$store.dispatch('user/resetPassword', {
          password: this.password,
          confirmPassword: this.passwordConfirm,
          token: this.$route.params.token,
          cb: (response) => {
            self.$store.commit({
              type: 'application/ADD_FEEDBACK',
              feedback: {
                type: 'success',
                message: 'Restablecimiento de contraseña con éxito.'
              }
            })
            self.$router.push({ path: '/clinic/login' })
          },
          cbError: (error) => {
            self.$store.commit({
              type: 'application/ADD_FEEDBACK',
              feedback: {
                type: 'error',
                message: error.response.data.error
              }
            })
          }
        })
      } else {
        this.$store.commit({
          type: 'application/ADD_FEEDBACK',
          feedback: {
            type: 'error',
            message: missingFields[0]
          }
        })
      }
    },
    formValidation () {
      var missingFields = []

      // password check
      if (this.password.length < 8) {
        missingFields.push('Password is too short')
      }

      if (IsEmptyOrNull(this.password) || this.password.trim() === '') {
        missingFields.push('Password is missing')
      }

      // confirm password check
      if (this.passwordConfirm.length < 8) {
        missingFields.push('Password confirm is too short')
      }
      // confirm password check
      if (IsEmptyOrNull(this.passwordConfirm) || this.passwordConfirm.trim() === '') {
        missingFields.push('Password confirm is missing')
      }

      // password confirmation check
      if (this.password.length > 7 && this.passwordConfirm.length > 7) {
        if (this.password.trim() !== this.passwordConfirm.trim()) {
          missingFields.push('Confirm password doesnt match')
          AddClassById('password', 'missing')
          AddClassById('password-confirm', 'missing')
        } else {
          RemoveClassById('password', 'missing')
          RemoveClassById('password-confirm', 'missing')
        }
      }

      return missingFields
    },
    goTo (path) {
      this.$router.push({ path: path })
    }
  },
  created () {
  }
}
</script>

<style scoped lang="scss">
.my-account-reset-password{
  width: 100%;
  section{
    width: 100%;
    padding: 0px 0px;
    &.over-hero{
      position: relative;
      margin-top: -340px;
      margin-bottom: 80px;
      z-index: 100;
    }
    .my-account-reset-password-wrap{
      margin: 0 auto;
      margin-bottom: 120px;
      width: 50%;
      border-radius: 4px;
      background-color: #FFFFFF;
      box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
      .content-my-account-reset-password-wrap{
        padding: 60px;
        .reset-password-wrap{
          padding: 0px 60px;
          form{
            .recover-pwd{
              float: right;
              text-decoration: underline;
            }
            button{
              float: left;
              margin-top: 48px;
            }
          }
          .error-reset-password-wrap{
            position: relative;
            top: 14px;
            p{
              padding: 7px;
              &.errorMessage{
                color: red;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .my-account-login{
    section{
      .my-account-reset-password-wrap{
        .content-my-account-reset-password-wrap{
          padding: 35px;
          .reset-password-wrap{
            border-right: 0px;
            overflow: hidden;
            form{
              button{
                display: block;
                line-height: 0px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .my-account-login{
    section{
      .my-account-reset-password-wrap{
        margin-bottom: 0px;
        .content-my-account-reset-password-wrap{
          .reset-password-wrap{
            padding: 0 14px;
          }
        }
      }
    }
  }
}
</style>
